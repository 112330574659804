/* header:not(.is-hidden-on-scroll) {
    background-color: #122223 !important;
} */
.hidden {
    top: -150px;
    /* Adjust this value based on your header's height */
}

.accommodation-section {
    padding-top: 80px
}

.d-mobile {
    display: none;
}

.hero.-type-10 .hero__content .hero__subtitle, .hero.-type-10 .hero__content .hero__title {
    text-shadow: 0 2px 2px #000;
}

.react-datepicker-wrapper, .datepicker, .react-datepicker {
    width: 100%;
}

#meetings-group .swiper-button-prev, #meetings-group .swiper-button-next {

    color: #f5f5f5;
    top: 35%;
    background-image: none;
    text-shadow: 0 2px 2px #222A77;
    border: 0;
    outline: none;
    background-color: #ffffff6f;
    box-shadow: 0 0 2px 2px #dee2e6;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#meetings-group .swiper-button-prev, #meetings-group .swiper-button-prev:after, #meetings-group .swiper-button-prev, #meetings-group .swiper-button-next:after {
    font-size: 20px !important;
}

.roomCard.-type-4 .roomCard__content {
    position: absolute;
    bottom: 0;
    left: 50%;
    text-align: center;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    transform: translateX(-50%);
    background: linear-gradient(to top, #293839a1, #293839a1, #29383936, transparent) !important;

}

.roomCard.-type-4 .roomCard__content h3 {

    color: #fff !important;
    font-weight: 600;
    text-shadow: 1px 1px 2px #000000;
    filter: drop-shadow(2px 2px 6px #fff);
}

@media screen and (min-width: 768px)and (max-width: 1350px) {
    .menuFullScreen-links-subnav {
        padding-left: 20px;
    }
}

@media screen and (min-width: 1351px)and (max-width: 1400px) {
    .menuFullScreen-links-subnav {
        padding-left: 100px;
    }
}

@media screen and (max-width: 767px) {
    .two .roomCardGrid {
        grid-template-columns: 1fr 1fr;
        display: flex;
        flex-direction: column-reverse;
    }

    .swiper-container {
        width: 100%;
    }

    .banner-mobile {
        height: 70vh !important;
    }

    .menuFullScreen__left {
        margin-top: 40px !important;
        margin-bottom: 0 !important;
    }

    .d-mobile {
        display: block;
    }

    .hero.-type-10 .hero__content {
        margin-bottom: 30vh;
    }

    .hero.-type-10 .hero__pagination {
        bottom: 5vh;
    }

    .mobile-transform-top {
        transform: RotateZ(270deg);
    }

    .mobile-transform-bottom {
        transform: RotateZ(90deg);
    }

    body {
        font-size: 14px;
    }

    .roomCard.-type-4 .roomCard__content {
        padding: 5px;
    }

    h3.roomCard__title {
        font-size: 20px !important;
    }

    #meetings-group .pagination.-type-1.-absolute {
        bottom: 57vh;
    }

    #meetings-group .swiper-button-prev, #meetings-group .swiper-button-next {
        top: 20% !important;
        color: #f5f5f5;
    }

    .roomCardGrid, .roomCard {
        grid-template-columns: 1fr !important;

    }

    .roomCard {
        padding-bottom: 50px;

        border-bottom: 1px solid #ccc;

    }

    .hero.-type-10 {
        height: 65vh;
    }

    div.language-options {
        min-width: 100px !important;

    }

    div.language-options .language-option {

        padding: 10px;
    }
}



.roomCardGrid {
    grid-template-columns: 1fr 1fr;
}

#gallery .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #222A77;
    opacity: 1;

}

#gallery [type=button]:not(:disabled), #gallery [type=reset]:not(:disabled), #gallery [type=submit]:not(:disabled), #gallery button:not(:disabled) {
    color: #222A77;
    opacity: 0.9;
    border-bottom: 1px solid;
}

#gallery .nav-tabs {
    --bs-nav-tabs-border-width: 0;
}

.contact-box {
    background-color: #fcfcfc;
    padding: 20px;
    box-shadow: 0 0 5px #ccc;
    border-radius: 10px;
}

.facilities-list .baseCard.-type-1 .baseCard__content {

    transition: all 2s ease-in-out;

}

.baseCard__image {
    position: relative;

}

.facilities-list .baseCard__image::after {
    content: "";
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
    display: block;
    transition: opacity 0.3s ease;
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.facilities-list .baseCard:hover .baseCard__image:after {
    top: 0;
}

.facilities-list .baseCard.-type-1 .baseCard__content p {
    display: none;
    transition: all 2s ease-in-out;
}

.facilities-list .baseCard.-type-1 .baseCard__content:hover p {
    display: block;
    transition: all 2s ease-in-out;
}

.header .button {
    text-transform: none;
}

@media screen and (max-width:767px) {
    .facilities-list .baseCard.-type-1 .baseCard__content p {
        display: block;
    }

    .facilities-list .baseCard .baseCard__image:after {
        top: 0;
    }

    h3.roomCard__title {
        color: #222A77 !important;
        font-weight: 600 !important;
        text-shadow: 1px 0px 0px #ccc !important;
    }
}